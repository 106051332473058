// This project is developed by [Derwing Medina](https://derwing-portfolio.netlify.app/) for IPS Nueva Popayan 
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore'; // Asegúrate de usar la versión correcta de Firestore
import { from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Company } from '../Models/company.model';

@Injectable({
  providedIn: 'root'
})
export class ActiveCompanyService {
  activeCompanyId: string | null = null;
  private company: Company | null = null;
  loading: boolean = false;
  showFooter: boolean = false;


  constructor(private firestore: AngularFirestore) {}

  // Método para buscar la empresa activa y almacenar el ID en sessionStorage
  fetchActiveCompany(): Observable<Company> {
    this.loading = true;
    // Primero verificar si los datos ya están en sessionStorage
    const storedCompanyId = sessionStorage.getItem('active_company');
    const storedCompanyData = sessionStorage.getItem('company');

    if (storedCompanyId && storedCompanyData) {
      // Si hay datos almacenados, parsearlos y devolverlos
      this.activeCompanyId = storedCompanyId;
      this.company = JSON.parse(storedCompanyData);
      this.loading = false;
      this.showFooter = true;
      return of(this.company as Company);
    }

    // Si no hay datos en sessionStorage, ir a Firebase
    return this.firestore
      .collection('active')
      .doc('Kwj4eiNnyXZZJIdxyrXx')
      .valueChanges()
      .pipe(
        map((doc: any) => {
          if (!doc || !doc.active_company) {
            this.loading = false;
            throw new Error('No se encontró el campo active_company');
          }
          return doc.active_company as string;
        }),
        tap((companyId: string) => {
          // Guardar el ID de la empresa activa en sessionStorage
          sessionStorage.setItem('active_company', companyId);
          this.activeCompanyId = companyId;
        }),
        switchMap((companyId: string) => {
          this.loading = false;
          return this.firestore.collection('companies').doc<Company>(companyId).valueChanges();
        }),
        map((companyData: Company | undefined) => {
          if (!companyData) {
            throw new Error('No se encontraron datos para la empresa activa');
          }
          this.loading = false;
          return companyData;
        }),
        tap((companyData: Company) => {
          // Guardar los datos de la empresa activa en la propiedad del servicio
          this.company = companyData;
          
          // Guardar la información completa de la empresa en sessionStorage
          sessionStorage.setItem('company', JSON.stringify(companyData));
          
          this.showFooter = true;
          this.loading = false;
        })
      );
  }

  setActiveCompany(companyData: Company) {
    this.company = new Company(companyData);
    sessionStorage.setItem('active_company', companyData.id);
  }

  getCompanyInfo() {
    return this.company ? this.company.getCompanyInfo() : 'No company selected';
  }

  // Otros métodos para manipular la compañía activa
  updateCompanyDetails(details: Partial<Company>) {
    if (this.company) {
      this.company.updateCompany(details);
    }
  }

  getCompany() {
    return this.company;
  }
  
  // Método para obtener la lista de empresas
  fetchCompanies(): Observable<Company[]> {
    return this.firestore.collection<Company>('companies').valueChanges();
  }
  
  // Método para establecer el ID de la empresa activa en la colección active
  setActiveCompanyId(companyId: string): Observable<void> {
    const activeCompanyDocRef = this.firestore.collection('active').doc('Kwj4eiNnyXZZJIdxyrXx');
    sessionStorage.setItem('active_company', companyId);
    // Convertir la promesa en un observable usando from
    return from(activeCompanyDocRef.update({ active_company: companyId }));
  }
}
